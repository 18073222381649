<template>
  <el-dialog
    :show-close="false"
    @close="closeDialog"
    :visible="addVisible"
    width="1200px"
  >
    <div slot="title" class="left_top">
      <div class="left_icon">
        <span></span> <span>{{ title }}</span>
      </div>
    </div>
    <el-form
      label-width="120px"
      ref="enterpriseFrom"
      :model="enterpriseFrom"
      :rules="rules"
      label-position="top"
      class="ruleForm"
    >
      <el-form-item label="企业名称 " prop="enterpriseName">
        <el-input v-model="enterpriseFrom.enterpriseName" disabled></el-input>
      </el-form-item>
      <el-form-item label="统一信用代码" prop="enterpriseCode">
        <el-input v-model="enterpriseFrom.enterpriseCode" disabled></el-input>
      </el-form-item>
      <el-form-item label="企业地址" prop="enterpriseArea">
        <el-input v-model="enterpriseFrom.enterpriseArea"></el-input>
      </el-form-item>
      <el-form-item label="企业电话" prop="enterprisePhone">
        <el-input v-model="enterpriseFrom.enterprisePhone"></el-input>
      </el-form-item>
      <el-form-item label="开户行" prop="enterpriseBank">
        <el-input v-model="enterpriseFrom.enterpriseBank"></el-input>
      </el-form-item>
      <el-form-item label="开户账号" prop="enterpriseBankCode">
        <el-input v-model="enterpriseFrom.enterpriseBankCode"></el-input>
      </el-form-item>
      <el-form-item label="收件人" prop="addresseeRecipients">
        <el-input v-model="enterpriseFrom.addresseeRecipients"></el-input>
      </el-form-item>
      <el-form-item label="收件电话" prop="addresseePhone">
        <el-input v-model="enterpriseFrom.addresseePhone"></el-input>
      </el-form-item>
      <el-form-item label="收件地址" prop="addresseeArea">
        <el-input v-model="enterpriseFrom.addresseeArea"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitForm('enterpriseFrom')"
        >确 定</el-button
      >
      <el-button @click="closeDialog()">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import * as _ from 'lodash';
//接口：
import { api } from '/src/api/base';
export const invoiceInfoDescribe = api()('invoiceInfo.describe.json');
//新增接口
export const invoiceInfoEdit = api()('invoiceInfo.edit.json');
export const enterpriseDescribe = api()('enterprise.describe.json');

export default {
  props: {
    addVisible: Boolean,
    index: Number,
  },
  watch: {
    index: async function(val, old) {
      if (val === old) return;
      const data1 = await this.getInvoiceInfo();
      const data2 = await this.getEnterpriseInfo();
      const data3 = {};
      data3.enterpriseName = data2.name;
      data3.enterpriseBank = data2.bankName;
      data3.enterpriseBankCode = data2.bankCode;
      data3.enterpriseCode = data2.idCardNumber;
      const _data = _.merge(data3, data1);
      Object.keys(this.enterpriseFrom).forEach((it) => {
        this.enterpriseFrom[it] = _data[it];
      });
    },
  },
  data() {
    return {
      title: '新增发票抬头',
      enterpriseFrom: {
        enterpriseName: '',
        enterpriseCode: '',
        enterpriseArea: '',
        enterpriseBank: '',
        enterpriseBankCode: '',
        addresseeRecipients: '',
        addresseePhone: '',
        addresseeArea: '',
        enterprisePhone: '',
      },
      rules: {
        enterpriseName: [
          { required: true, message: '请输入企业名称', trigger: 'blur' },
        ],
        enterpriseCode: [
          { required: true, message: '请输入统一信用代码', trigger: 'blur' },
        ],
        enterpriseArea: [
          { required: true, message: '请输入企业地址', trigger: 'blur' },
        ],
        enterpriseBank: [
          { required: true, message: '请输入开户行', trigger: 'blur' },
        ],
        enterpriseBankCode: [
          { required: true, message: '请输入开户账号', trigger: 'blur' },
        ],
        addresseeRecipients: [
          { required: true, message: '请输入收件人', trigger: 'blur' },
        ],
        addresseePhone: [
          { required: true, message: '请输入收件电话', trigger: 'blur' },
        ],
        enterprisePhone: [
          { required: true, message: '请输入企业电话', trigger: 'blur' },
        ],
        addresseeArea: [
          { required: true, message: '请输入收件地址', trigger: 'blur' },
        ],
      },
    };
  },
  methods: {
    async getInvoiceInfo() {
      const res = await invoiceInfoDescribe({
        enterpriseAccount: window.localStorage.getItem('enterprise'),
      });
      if (res) {
        this.title = '编辑发票抬头';
        return res;
      }
    },
    async getEnterpriseInfo() {
      return await enterpriseDescribe({
        code: window.localStorage.getItem('enterprise'),
      });
    },
    async submitForm(form) {
      this.$refs[form].validate(async (valid) => {
        if (valid) {
          await invoiceInfoEdit({
            enterpriseAccount: window.localStorage.getItem('enterprise'),
            ...this.enterpriseFrom,
          });
          this.$message.success(
            this.title === '新增发票抬头' ? '填写成功' : '编辑成功',
          );
          this.closeDialog();
        }
      });
    },
    closeDialog() {
      this.$emit('update:addVisible', false);
      this.$emit('addClose');
    },
  },
};
</script>

<style scoped lang="scss">
.ruleForm {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.el-form-item {
  text-align: left;
  margin: 0;
  margin-bottom: 10px;
  width: 49%;
}
</style>
